import {useEffect, FC, PropsWithChildren} from 'react';
import {useQuery} from '@apollo/client';
import {useSelector, useDispatch} from 'react-redux';
import {OrderTypes, orderTypeToDescription} from '@hy-vee/shared-utils-aisles-online';
import {Alert} from '@hy-vee/web-core';
import {useRouter} from 'next/router';

import {setReservationStepperIndex} from 'client/action-creators';
import {AOH_IN_STORE_ONLY} from 'client/enums/feature-toggle-names';

import StoreSelectionPickupDisplay from '../store-selection/pickup/store-selection-pickup-display';
import StoreSelectionDeliveryDisplay from '../store-selection/delivery/store-selection-delivery-display';
import StoreSelectionDeliveryFulfillmentSelectionDisplay from '../store-selection/delivery/store-selection-delivery-fulfillment-selection-display';
import {getActiveCartForReservationStepper} from '../../../graphql/queries/cart-queries';
import {graphqlClient} from '../../../graphql/graphql-client';
import {
    getAddressAccordionHeader,
    getFulfillmentTimeIsValid,
    getPickupReservationAccordionHeader,
    getTimeSlotAccordionHeader,
    getTimeSlotStatusType
} from '../../../utils/view-helpers/accordion-header-view-helpers';
import {useReservation} from '../../../hooks/use-reservation';
import TimeReservation from '../../time-reservation';
import {
    getFulfillmentLocationByFulfillmentLocationId,
    getFulfillmentTimes
} from '../../../graphql/queries/fulfillment-location-queries';
import DisableSectionLoader from '../disable-section-loader';
import {isLoading} from '../../../utils/view-helpers/loading-indicator-view-helpers';
import {
    RESET_FULFILLMENT_CHANGE_REMOVED_CART_ITEMS,
    SET_STORE_SELECTION_DELIVERY_PAGE_DATA_REQUEST,
    SET_TIME_RESERVATION_PAGE_DATA_REQUEST
} from '../../../action-types';
import {DELIVERY_FULFILLMENT_SELECTION} from '../../../enums/store-selection-tab-types';
import {FULFILLMENT, LOCATION, REVIEW, SKIPPED, TIME_SLOT} from '../../../enums/reservation-stepper-enums';
import {getActiveCartForReservationStepper as getActiveCartForReservationStepperData} from '../../../../autogen/getActiveCartForReservationStepper';
import {
    getFulfillmentTimes as getFulfillmentTimesData,
    getFulfillmentTimesVariables
} from '../../../../autogen/getFulfillmentTimes';
import {useFeatureToggle} from '../../../context/feature-toggle';
import {useGlobalCallout} from '../home-page/hooks';
import {
    getFulfillmentLocationByFulfillmentLocationId as getFulfillmentLocationData,
    getFulfillmentLocationByFulfillmentLocationIdVariables
} from '../../../../autogen/getFulfillmentLocationByFulfillmentLocationId';
import {locationIsIneligible, locationIsDeliveryOnly} from '../../../utils/view-helpers/fulfillment-view-helpers';
import {useCustomerId} from '../../../hooks/customer-hooks';
import {getDeliveryAddressesByCustomerId} from '../../../graphql/queries/delivery-address-queries';
import {
    getDeliveryAddressesByCustomerIdVariables,
    getDeliveryAddressesByCustomerId as getDeliveryAddressData
} from '../../../../autogen/getDeliveryAddressesByCustomerId';
import {LocationEcommerceStatus} from '../../../../autogen/globalTypes';

import ReservationStepperFooter from './reservation-stepper-footer';
import BasketContinuityReviewStep from './basket-continuity-review-step';
import {ReservationAccordionHeader} from './reservation-accordion-header';
import ShoppingPreferencesBody from './shopping-preference-body';
import ReservationStepperAccordionItem from './reservation-stepper-accordion-item';
import {isEcommerceInactive, isCheckoutFlow} from './helpers';

const isDelivery = (fulfillmentType) => fulfillmentType === OrderTypes.DELIVERY;

const renderDeliveryView: FC<PropsWithChildren<PropsWithChildren>> = (storeSelectionTypeTab: any) => {
    if (storeSelectionTypeTab === DELIVERY_FULFILLMENT_SELECTION) {
        return (
            <StoreSelectionDeliveryFulfillmentSelectionDisplay
                data-testid={'store-selection-delivery-fulfillment-selection-display'}
                isReservationStepper
            />
        );
    }

    return <StoreSelectionDeliveryDisplay isReservationStepper />;
};

const getAlcoholReservationDisclaimer = (
    requireSeparateAlcoholOrder: boolean,
    cartContainsAlcohol: boolean,
    canDeliverAlcohol: boolean
): string => {
    if (cartContainsAlcohol) {
        if (requireSeparateAlcoholOrder) {
            return 'There are two pickup locations';
        }

        if (!canDeliverAlcohol) {
            return 'Alcohol cannot be delivered and will be removed from your cart when you save';
        }
    }

    return '';
};

export interface IReservationStepperContent {
    closeModal: () => void;
    redirectOnChange?: boolean;
    reservationStepperIndex?: string;
}

const ReservationStepperContent: FC<PropsWithChildren<PropsWithChildren<IReservationStepperContent>>> = ({
    closeModal,
    redirectOnChange,
    reservationStepperIndex
}) => {
    const {
        cartContainsAlcohol,
        deliveryLocation,
        deliveryLocationCanFulfillAlcohol,
        fulfillmentLocationId,
        fulfillmentTime,
        fulfillmentType,
        isUpdatingCart,
        onSelectDelivery,
        onSelectPickup,
        onSetSelectedIndex,
        setFulfillmentTime,
        pickupLocation,
        selectedIndex,
        setCartContainsAlcohol,
        isStoreEcommerceInactive,
        setIsStoreEcommerceInactive,
        setFulfillmentLocationInactive,
        setInitialState
    } = useReservation();
    const customerId = useCustomerId();

    const {featureEnabled} = useFeatureToggle();

    const router = useRouter();
    const shouldShowTimeReservationView = isCheckoutFlow(router.pathname);

    const customerHasAislesOnlineMembership = useSelector((state: any) => state.customerHasAislesOnlineMembership);
    const loadingIndicators = useSelector((state: any) => state.loadingIndicators);
    const storeSelectionTypeTab = useSelector((state: any) => state.storeSelection.storeSelectionTypeTab);
    const timeSlotSnatchedError = useSelector((state: any) => state.timeSlotSnatchedError);

    const {data: fulfillmentLocationData, loading: fulfillmentLocationLoading} = useQuery<
        getFulfillmentLocationData,
        getFulfillmentLocationByFulfillmentLocationIdVariables
    >(getFulfillmentLocationByFulfillmentLocationId, {
        client: graphqlClient(),
        skip: !fulfillmentLocationId,
        variables: {
            fulfillmentLocationId: Number(fulfillmentLocationId)
        }
    });
    const {
        data: deliveryData,
        loading: deliveryLoading,
        refetch
    } = useQuery<getDeliveryAddressData, getDeliveryAddressesByCustomerIdVariables>(getDeliveryAddressesByCustomerId, {
        client: graphqlClient(),
        skip: !customerId,
        variables: {
            customerId: customerId || 0
        }
    });

    const {data: cartData, loading: cartLoading} = useQuery<getActiveCartForReservationStepperData>(
        getActiveCartForReservationStepper,
        {
            client: graphqlClient()
        }
    );

    const activeCart = cartData?.carts && cartData?.carts[0];
    const fulfillmentLocation = activeCart?.fulfillmentLocation;
    const activeCartFulfillmentLocationId = fulfillmentLocation?.fulfillmentLocationId;

    const {data: fulfillmentTimesData, loading: fulfillmentTimesLoading} = useQuery<
        getFulfillmentTimesData,
        getFulfillmentTimesVariables
    >(getFulfillmentTimes, {
        client: graphqlClient(),
        skip: !activeCartFulfillmentLocationId,
        variables: {
            fulfillmentLocationId: Number(activeCartFulfillmentLocationId)
        }
    });

    // TDI: rewrite in a more optimized way
    useEffect(() => {
        if (deliveryLocation !== null) refetch();
    }, [deliveryLocation, refetch]);

    useEffect(() => {
        if (deliveryData && deliveryLocation) {
            let ecomInactive = true;

            const matchingDeliveryDataFromGQL = deliveryData.deliveryAddresses?.find((deliveryLocationFromGQL) => {
                return Number(deliveryLocationFromGQL.deliveryAddressId) === Number(deliveryLocation.deliveryAddressId);
            });

            if (
                matchingDeliveryDataFromGQL?.fulfillmentLocations?.length &&
                matchingDeliveryDataFromGQL.fulfillmentLocations[0].fulfillmentStoreLocation
            ) {
                ecomInactive =
                    matchingDeliveryDataFromGQL.fulfillmentLocations[0].fulfillmentStoreLocation.ecommerceStatus ===
                    LocationEcommerceStatus.INACTIVE;
            }

            setIsStoreEcommerceInactive(ecomInactive);
        }
    }, [deliveryLocation, deliveryData, setIsStoreEcommerceInactive]);

    const dispatch = useDispatch();

    useEffect(() => {
        const fulfillmentTimes = fulfillmentTimesData?.fulfillmentTimes || [];

        setIsStoreEcommerceInactive(
            cartData?.carts
                ? isEcommerceInactive(cartData.carts[0].fulfillmentLocation?.fulfillmentStoreLocation)
                : false
        );

        setInitialState(activeCart, fulfillmentTimes, Number(reservationStepperIndex));
        dispatch({
            removedCartItems: [],
            type: RESET_FULFILLMENT_CHANGE_REMOVED_CART_ITEMS
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartData, fulfillmentTimesData]);

    useEffect(() => {
        setCartContainsAlcohol(activeCart?.cartItems?.some((item) => item.storeProduct?.isAlcohol) || false);
    }, [activeCart, setCartContainsAlcohol]);

    const isFulfillmentTypeSelected = fulfillmentType && fulfillmentType !== OrderTypes.NOT_SELECTED;
    const isFulfillmentLocationSelected = pickupLocation || deliveryLocation;
    const isFulfillmentTimeValid = getFulfillmentTimeIsValid(fulfillmentTime, shouldShowTimeReservationView);

    const requireSeparateAlcoholOrder = pickupLocation?.requireSeparateAlcoholOrder || false;

    const isInStoreOnlyEnabled = featureEnabled(AOH_IN_STORE_ONLY);

    const alcoholReservationDisclaimer = getAlcoholReservationDisclaimer(
        requireSeparateAlcoholOrder,
        cartContainsAlcohol,
        deliveryLocationCanFulfillAlcohol
    );

    const isActiveCartStoreInactive = cartData?.carts
        ? isEcommerceInactive(cartData.carts[0].fulfillmentLocation?.fulfillmentStoreLocation)
        : false;

    const {data: globalCallout} = useGlobalCallout();

    useEffect(() => {
        if (isActiveCartStoreInactive) {
            dispatch(setReservationStepperIndex(FULFILLMENT));
        }
    }, [isActiveCartStoreInactive, dispatch]);

    const locationInactive = locationIsIneligible(
        fulfillmentLocationData?.fulfillmentLocation?.fulfillmentStoreLocation?.ecommerceStatus,
        featureEnabled
    );

    useEffect(() => {
        setFulfillmentLocationInactive(locationInactive);

        if (locationInactive && selectedIndex[0] === TIME_SLOT) {
            onSetSelectedIndex(REVIEW);
            setFulfillmentTime(SKIPPED);
        }
    }, [locationInactive, selectedIndex, onSetSelectedIndex, setFulfillmentLocationInactive, setFulfillmentTime]);

    useEffect(() => {
        if (!shouldShowTimeReservationView && selectedIndex[0] === TIME_SLOT) {
            onSetSelectedIndex(REVIEW);
            setFulfillmentTime(SKIPPED);
        }
    }, [shouldShowTimeReservationView, selectedIndex, onSetSelectedIndex, setFulfillmentTime]);

    return (
        <DisableSectionLoader
            isLoading={
                cartLoading ||
                fulfillmentTimesLoading ||
                isUpdatingCart ||
                fulfillmentLocationLoading ||
                deliveryLoading ||
                isLoading(loadingIndicators, SET_TIME_RESERVATION_PAGE_DATA_REQUEST) ||
                isLoading(loadingIndicators, SET_STORE_SELECTION_DELIVERY_PAGE_DATA_REQUEST)
            }
        >
            <div data-testid="reservation-stepper">
                <ReservationStepperAccordionItem
                    accordionText="Shopping Preference"
                    data-testid="accordion-item-fulfillment"
                    disabled={false}
                    isOpen={selectedIndex[0] === FULFILLMENT}
                    onClick={() => onSetSelectedIndex(FULFILLMENT)}
                    testId="accordion-item-fulfillment"
                >
                    <ReservationAccordionHeader
                        alcoholReservationDisclaimer={alcoholReservationDisclaimer}
                        isDeliveryOnly={locationIsDeliveryOnly(
                            fulfillmentLocationData?.fulfillmentLocation?.fulfillmentStoreLocation?.ecommerceStatus
                        )}
                        isSelected={
                            fulfillmentType &&
                            fulfillmentType !== OrderTypes.NOT_SELECTED &&
                            (!isActiveCartStoreInactive ||
                                (isActiveCartStoreInactive && selectedIndex[0] !== FULFILLMENT))
                        }
                        notSelectedText={selectedIndex[0] === FULFILLMENT ? '' : undefined}
                        onChange={() => onSetSelectedIndex(FULFILLMENT)}
                        shouldShowThirdPartyDisclaimer={isDelivery(fulfillmentType) && selectedIndex[0] !== 0}
                        showChangeButton={!(selectedIndex[0] === FULFILLMENT) && isFulfillmentTypeSelected}
                        subtitle={<strong>{orderTypeToDescription[fulfillmentType]}</strong>}
                        testId={'accordion-header-preference'}
                        title="Shopping Preference"
                    />
                    <div>
                        {isActiveCartStoreInactive && globalCallout && (
                            <Alert data-testid="global-alert" status="warning">
                                {globalCallout.heading} <strong>{globalCallout.subText}</strong>
                            </Alert>
                        )}
                        <ShoppingPreferencesBody
                            customerHasAislesOnlineMembership={customerHasAislesOnlineMembership}
                            fulfillmentType={fulfillmentType}
                            onSelectDelivery={onSelectDelivery}
                            onSelectPickup={onSelectPickup}
                            requireSeparateAlcoholOrder={requireSeparateAlcoholOrder && cartContainsAlcohol}
                            shouldShowAlcoholDeliverySubtext={!deliveryLocationCanFulfillAlcohol && cartContainsAlcohol}
                        />
                    </div>
                </ReservationStepperAccordionItem>
                <ReservationStepperAccordionItem
                    accordionText="Location"
                    data-testid="accordion-item-location"
                    disabled={!isFulfillmentTypeSelected}
                    isOpen={selectedIndex[0] === LOCATION}
                    onClick={() => onSetSelectedIndex(LOCATION)}
                    testId={'accordion-item-location'}
                >
                    <ReservationAccordionHeader
                        isDeliveryOnly={locationIsDeliveryOnly(
                            fulfillmentLocationData?.fulfillmentLocation?.fulfillmentStoreLocation?.ecommerceStatus
                        )}
                        isLocationHeader
                        isSelected={isFulfillmentLocationSelected}
                        notSelectedText={selectedIndex[0] === LOCATION ? '' : undefined}
                        onChange={() => onSetSelectedIndex(LOCATION)}
                        showChangeButton={!(selectedIndex[0] === LOCATION) && isFulfillmentLocationSelected}
                        showInStoreOnlyText={isInStoreOnlyEnabled && isStoreEcommerceInactive}
                        subtitle={
                            isDelivery(fulfillmentType)
                                ? getAddressAccordionHeader(deliveryLocation)
                                : getPickupReservationAccordionHeader(pickupLocation)
                        }
                        testId={'accordion-header-location'}
                        title="Location"
                    />
                    {isDelivery(fulfillmentType) ? (
                        renderDeliveryView(storeSelectionTypeTab)
                    ) : (
                        <StoreSelectionPickupDisplay />
                    )}
                </ReservationStepperAccordionItem>
                {!locationInactive ? (
                    <ReservationStepperAccordionItem
                        accordionText="Time Slot"
                        data-testid="accordion-item-time-slot"
                        disabled={!isFulfillmentLocationSelected}
                        isOpen={selectedIndex[0] === TIME_SLOT}
                        onClick={() => onSetSelectedIndex(TIME_SLOT)}
                        testId="accordion-item-time-slot"
                    >
                        <ReservationAccordionHeader
                            isDeliveryOnly={locationIsDeliveryOnly(
                                fulfillmentLocationData?.fulfillmentLocation?.fulfillmentStoreLocation?.ecommerceStatus
                            )}
                            isSelected={isFulfillmentTimeValid}
                            isTimeSlotHeader
                            notSelectedText={selectedIndex[0] === TIME_SLOT ? '' : undefined}
                            onChange={() => onSetSelectedIndex(TIME_SLOT)}
                            shouldShowTimeReservationView={shouldShowTimeReservationView}
                            showChangeButton={!(selectedIndex[0] === TIME_SLOT) && isFulfillmentTimeValid}
                            statusType={getTimeSlotStatusType(
                                fulfillmentTime,
                                timeSlotSnatchedError,
                                shouldShowTimeReservationView
                            )}
                            subtitle={getTimeSlotAccordionHeader(
                                fulfillmentTime,
                                timeSlotSnatchedError,
                                shouldShowTimeReservationView
                            )}
                            testId={'accordion-header-time-slot'}
                            title="Time Slot"
                        />
                        {fulfillmentLocationId && shouldShowTimeReservationView && (
                            <TimeReservation
                                fulfillmentLocationId={fulfillmentLocationId}
                                isSelected={isFulfillmentTimeValid}
                                onSetSelectedIndex={onSetSelectedIndex}
                            />
                        )}
                    </ReservationStepperAccordionItem>
                ) : null}
                <BasketContinuityReviewStep redirectOnChange={redirectOnChange} />
                <ReservationStepperFooter closeModal={closeModal} redirectOnChange={redirectOnChange} />
            </div>
        </DisableSectionLoader>
    );
};

export default ReservationStepperContent;

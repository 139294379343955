import {useSelector, useDispatch} from 'react-redux';
import {green} from '@hy-vee/themes';
import {isToday, parseISO, isAfter} from 'date-fns';
import {format, utcToZonedTime} from 'date-fns-tz';
import {WarningIcon} from '@hy-vee/icons';

import {getFormattedTime} from '../../../../utils/view-helpers/date-time-view-helpers';
import {
    isFulfillmentTimeSelected,
    isReservationStepperTimeSelected
} from '../../../../utils/view-helpers/fulfillment-view-helpers';
import {useReservation} from '../../../../hooks/use-reservation';
import {selectedBackgroundColor, StyledSelectionCheckmark} from '../../../../styles/style-constants';
import {setTimeSlotSnatchedError, setFulfillmentTimeError} from '../../../../action-creators';
import {PAST_CUTOFF_TIME} from '../../../../enums/error-types';
import FulfillmentTimeSelect from '../fulfillment-time-select';
import FulfillmentPrice from '../fulfillment-price';
import {TimeSlotRow, EmptyPrice, NotAvailableMessage, TimeContainer, TimeRow} from '../styles';

import styles from './index.module.css';

const getDisplayUnderTime = (fulfillmentTime, isSelected, cart, selectedTimeReservationTab) => {
    if (isSelected) {
        return `Currently reserved until ${getFormattedTime(cart.fulfillmentExpiration)}`;
    }

    if (selectedTimeReservationTab > 1 || !fulfillmentTime.isAvailable) {
        return '';
    }

    if (isToday(parseISO(fulfillmentTime.cutoffTime))) {
        return `Order by ${getFormattedTime(fulfillmentTime.cutoffTime)} Today`;
    }

    return `Order by ${getFormattedTime(fulfillmentTime.cutoffTime)} on ${format(
        utcToZonedTime(new Date(fulfillmentTime.cutoffTime), 'America/Chicago'),
        'EEEE'
    )}`;
};

const FulfillmentTime = ({index, fulfillmentTime, row}) => {
    const {
        cart,
        cartItems,
        customerHasAislesOnlineMembership,
        fulfillmentFees,
        products,
        selectedTimeReservationTab,
        storeProducts
    } = useSelector((state) => {
        return {
            cart: state.cart,
            cartItems: state.cartItems,
            customerHasAislesOnlineMembership: state.customerHasAislesOnlineMembership,
            fulfillmentFees: state.fulfillmentFees,
            products: state.products,
            selectedTimeReservationTab: state.timeReservation.selectedTimeReservationTab,
            storeProducts: state.storeProducts
        };
    });

    const reservation = useReservation();
    const reservationStepperFulfillmentTime = reservation.fulfillmentTime;
    const cartContainsAlcohol = reservation.cartContainsAlcohol && fulfillmentTime.preventAlcohol;
    const isSelected = isFulfillmentTimeSelected(fulfillmentTime, cart, reservationStepperFulfillmentTime);
    const reservationStepperIsSelected = isReservationStepperTimeSelected(
        fulfillmentTime,
        reservationStepperFulfillmentTime
    );
    const isFulfillmentTimeAvailableOrSelected = fulfillmentTime.isAvailable || isSelected;
    const dispatch = useDispatch();
    const onSelectTimeSlot = () => {
        const fulfillmentTimeToCompare = reservationStepperFulfillmentTime || fulfillmentTime;
        const cutoffTimeHasExpired = isAfter(Date.now(), new Date(fulfillmentTimeToCompare.cutoffTime));

        if (cutoffTimeHasExpired) {
            return dispatch(setFulfillmentTimeError(fulfillmentTimeToCompare.fulfillmentTimeId, PAST_CUTOFF_TIME));
        }

        dispatch(setTimeSlotSnatchedError(false));

        return reservation.onSelectFulfillmentTime(fulfillmentTime);
    };

    return (
        <TimeSlotRow
            backgroundColor={reservationStepperIsSelected && selectedBackgroundColor}
            data-testid={'fulfillment-time-row'}
            onClick={isFulfillmentTimeAvailableOrSelected ? onSelectTimeSlot : undefined}
        >
            <TimeContainer
                available={isFulfillmentTimeAvailableOrSelected}
                backgroundColor={reservationStepperIsSelected && selectedBackgroundColor}
                data-testid={'time-container'}
                row={row}
            >
                <TimeRow>
                    <span className={styles.time}>
                        <strong id={`fulfillment-time-${index}`}>
                            {`${getFormattedTime(fulfillmentTime.windowStart)} - ${getFormattedTime(
                                fulfillmentTime.windowEnd
                            )}`}
                        </strong>
                    </span>
                    {reservationStepperIsSelected && (
                        <StyledSelectionCheckmark color={green.mediumGreen} data-testid={'checkmark'} size="small" />
                    )}
                </TimeRow>
                <span
                    className={isSelected ? styles.cutoffSelected : styles.cutoff}
                    data-testid={'cutoff-message'}
                    id={`fulfillment-cutoff-${index}`}
                >
                    {getDisplayUnderTime(fulfillmentTime, isSelected, cart, selectedTimeReservationTab)}
                </span>
                {cartContainsAlcohol && fulfillmentTime.preventAlcohol && (
                    <span
                        className={isSelected ? styles.cutoffSelected : styles.cutoff}
                        id={`fulfillment-cutoff-${index}`}
                    >
                        <WarningIcon className={styles.warningIcon} size="small" />
                        <span>{'Alcohol item(s) not available'}</span>
                    </span>
                )}
            </TimeContainer>
            {isFulfillmentTimeAvailableOrSelected ? (
                <FulfillmentPrice
                    backgroundColor={reservationStepperIsSelected && selectedBackgroundColor}
                    cartItems={cartItems}
                    customerHasAislesOnlineMembership={customerHasAislesOnlineMembership}
                    data-testid={'fulfillment-price'}
                    fulfillmentFees={fulfillmentFees}
                    fulfillmentTime={fulfillmentTime}
                    index={index}
                    products={products}
                    row={row}
                    storeProducts={storeProducts}
                />
            ) : (
                <EmptyPrice backgroundColor={reservationStepperIsSelected && selectedBackgroundColor} row={row} />
            )}
            {isFulfillmentTimeAvailableOrSelected ? (
                <FulfillmentTimeSelect
                    backgroundColor={reservationStepperIsSelected && selectedBackgroundColor}
                    fulfillmentTime={fulfillmentTime}
                    index={index}
                    row={row}
                />
            ) : (
                <NotAvailableMessage row={row}>{'Not available'}</NotAvailableMessage>
            )}
        </TimeSlotRow>
    );
};

export default FulfillmentTime;
